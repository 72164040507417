var exports = {};
exports = {
  A: {
    A: {
      "1": "B",
      "2": "J E GC",
      "66": "F G A"
    },
    B: {
      "1": "C K L H M N O P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "1": "3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB",
      "2": "0 1 HC wB I z J E F G A B C K L H M N O j k IC JC",
      "66": "2",
      "129": "jB kB lB mB nB l oB pB qB rB",
      "388": "sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B"
    },
    D: {
      "1": "1 2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T",
      "2": "0 I z J E F G A B C K L H M N O j k",
      "1540": "U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC"
    },
    E: {
      "1": "E F G A B C K OC PC 3B tB uB",
      "2": "I z J LC 2B MC NC",
      "513": "L H 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB uB",
      "2": "G B C UC VC WC XC tB EC YC",
      "1540": "l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i"
    },
    G: {
      "1": "F aC bC cC dC eC fC gC hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "2": "2B ZC FC"
    },
    H: {
      "1": "tC"
    },
    I: {
      "1": "D",
      "2": "wB I uC vC wC xC FC yC zC"
    },
    J: {
      "1": "A",
      "2": "E"
    },
    K: {
      "1": "l uB",
      "2": "A B C tB EC"
    },
    L: {
      "1": "D"
    },
    M: {
      "129": "D"
    },
    N: {
      "1": "B",
      "66": "A"
    },
    O: {
      "1": "0C"
    },
    P: {
      "1": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "1": "4B"
    },
    R: {
      "1": "ED"
    },
    S: {
      "1": "FD GD"
    }
  },
  B: 6,
  C: "TLS 1.1"
};
export default exports;