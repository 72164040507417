var exports = {};
exports = {
  A: {
    A: {
      "1": "G A B",
      "2": "GC",
      "8": "J E F"
    },
    B: {
      "1": "C K L H M N O",
      "129": "P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB IC JC",
      "8": "HC wB",
      "129": "YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB",
      "4": "I",
      "129": "TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC"
    },
    E: {
      "1": "z J E F G B C K L H MC NC OC PC 3B tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "8": "I LC 2B",
      "129": "A"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 B C M N O j k AB BB CB DB EB FB GB HB XC tB EC YC uB",
      "2": "G H UC",
      "8": "VC WC",
      "129": "IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i"
    },
    G: {
      "1": "F 2B ZC FC aC bC cC dC eC fC",
      "129": "gC hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "1": "wB I uC vC wC xC FC yC zC",
      "129": "D"
    },
    J: {
      "1": "E A"
    },
    K: {
      "1": "B C tB EC uB",
      "8": "A",
      "129": "l"
    },
    L: {
      "129": "D"
    },
    M: {
      "129": "D"
    },
    N: {
      "1": "A B"
    },
    O: {
      "129": "0C"
    },
    P: {
      "1": "I",
      "129": "j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "129": "4B"
    },
    R: {
      "129": "ED"
    },
    S: {
      "1": "FD",
      "129": "GD"
    }
  },
  B: 2,
  C: "Geolocation"
};
export default exports;