var exports = {};
exports = {
  A: {
    A: {
      "16": "GC",
      "132": "J E F G A B"
    },
    B: {
      "1": "O P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D",
      "132": "C K L H M N"
    },
    C: {
      "1": "ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B",
      "132": "0 1 2 3 4 5 6 7 HC wB I z J E F G A B C K L H M N O j k IC JC",
      "260": "VB WB XB YB",
      "772": "8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB"
    },
    D: {
      "1": "lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "132": "0 1 2 I z J E F G A B C K L H M N O j k",
      "260": "HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB",
      "772": "3 4 5 6 7 8 9 AB BB CB DB EB FB GB"
    },
    E: {
      "1": "C K L H uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "16": "I z LC 2B",
      "132": "J E F G A MC NC OC PC",
      "260": "B 3B tB"
    },
    F: {
      "1": "aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i",
      "16": "G B C UC VC WC XC tB EC YC",
      "132": "uB",
      "260": "4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB",
      "772": "0 1 2 3 H M N O j k"
    },
    G: {
      "1": "hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "16": "2B ZC FC aC",
      "132": "F bC cC dC eC fC gC"
    },
    H: {
      "132": "tC"
    },
    I: {
      "1": "D",
      "16": "wB uC vC wC",
      "132": "I xC FC",
      "772": "yC zC"
    },
    J: {
      "132": "E A"
    },
    K: {
      "1": "l",
      "16": "A B C tB EC",
      "132": "uB"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "132": "A B"
    },
    O: {
      "1": "0C"
    },
    P: {
      "1": "j k 5C 3B 6C 7C 8C 9C AD vB BD CD DD",
      "260": "I 1C 2C 3C 4C"
    },
    Q: {
      "1": "4B"
    },
    R: {
      "1": "ED"
    },
    S: {
      "1": "GD",
      "132": "FD"
    }
  },
  B: 6,
  C: "Date.prototype.toLocaleDateString"
};
export default exports;