var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F G GC",
      "8": "A B"
    },
    B: {
      "1": "P",
      "2": "Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D",
      "8": "C K L H M N O"
    },
    C: {
      "2": "0 1 HC wB I z J E F G A B C K L H M N O j k xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B IC JC",
      "66": "2 3 4 5 6 7 8",
      "72": "9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB"
    },
    D: {
      "1": "CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P",
      "2": "0 1 2 3 4 5 I z J E F G A B C K L H M N O j k Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "66": "6 7 8 9 AB BB"
    },
    E: {
      "2": "I z LC 2B MC",
      "8": "J E F G A B C K L H NC OC PC 3B tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC"
    },
    F: {
      "1": "1 2 3 4 5 6 7 8 9 j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB",
      "2": "G B C iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i UC VC WC XC tB EC YC uB",
      "66": "0 H M N O"
    },
    G: {
      "2": "2B ZC FC aC bC",
      "8": "F cC dC eC fC gC hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "1": "zC",
      "2": "wB I D uC vC wC xC FC yC"
    },
    J: {
      "2": "E A"
    },
    K: {
      "2": "A B C l tB EC uB"
    },
    L: {
      "2": "D"
    },
    M: {
      "2": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "0C"
    },
    P: {
      "1": "I 1C 2C 3C 4C 5C 3B 6C 7C",
      "2": "j k 8C 9C AD vB BD CD DD"
    },
    Q: {
      "1": "4B"
    },
    R: {
      "2": "ED"
    },
    S: {
      "2": "GD",
      "72": "FD"
    }
  },
  B: 7,
  C: "Custom Elements (deprecated V0 spec)"
};
export default exports;