var exports = {};
exports = {
  A: {
    A: {
      "4": "J E F G A B GC"
    },
    B: {
      "4": "C K L H M N O P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "1": "HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B",
      "8": "0 1 2 3 4 5 6 7 8 9 HC wB I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB IC JC"
    },
    D: {
      "4": "0 1 2 3 4 5 6 7 8 9 z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "8": "I"
    },
    E: {
      "4": "z J E F G A B C K L H MC NC OC PC 3B tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "8": "I LC 2B"
    },
    F: {
      "4": "0 1 2 3 4 5 6 7 8 9 H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i",
      "8": "G B C UC VC WC XC tB EC YC uB"
    },
    G: {
      "4": "F aC bC cC dC eC fC gC hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "8": "2B ZC FC"
    },
    H: {
      "8": "tC"
    },
    I: {
      "4": "wB I D xC FC yC zC",
      "8": "uC vC wC"
    },
    J: {
      "4": "A",
      "8": "E"
    },
    K: {
      "4": "l",
      "8": "A B C tB EC uB"
    },
    L: {
      "4": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "4": "A B"
    },
    O: {
      "4": "0C"
    },
    P: {
      "4": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "4": "4B"
    },
    R: {
      "4": "ED"
    },
    S: {
      "1": "FD GD"
    }
  },
  B: 1,
  C: "Ruby annotation"
};
export default exports;