var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F G A B GC"
    },
    B: {
      "2": "C K L H M N O",
      "258": "P Q R S T U",
      "322": "V W",
      "388": "X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "2": "0 1 2 3 4 5 6 7 8 9 HC wB I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l IC JC",
      "258": "oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B"
    },
    D: {
      "2": "0 1 2 3 4 5 6 7 8 9 I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB",
      "258": "cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U",
      "322": "V W",
      "388": "X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC"
    },
    E: {
      "2": "I z J E F G A B LC 2B MC NC OC PC 3B",
      "258": "C K L H tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC"
    },
    F: {
      "2": "0 1 2 3 4 5 6 7 8 9 G B C H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB UC VC WC XC tB EC YC uB",
      "258": "QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB",
      "322": "nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d",
      "388": "e f g h i"
    },
    G: {
      "2": "F 2B ZC FC aC bC cC dC eC fC gC hC iC",
      "258": "jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "2": "wB I uC vC wC xC FC yC zC",
      "258": "D"
    },
    J: {
      "2": "E A"
    },
    K: {
      "2": "A B C tB EC uB",
      "388": "l"
    },
    L: {
      "388": "D"
    },
    M: {
      "258": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "2": "0C"
    },
    P: {
      "2": "I 1C 2C 3C",
      "258": "j k 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "258": "4B"
    },
    R: {
      "388": "ED"
    },
    S: {
      "2": "FD",
      "258": "GD"
    }
  },
  B: 5,
  C: "Permissions Policy"
};
export default exports;