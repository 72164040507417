var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F G GC",
      "900": "A B"
    },
    B: {
      "1": "N O P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D",
      "388": "L H M",
      "900": "C K"
    },
    C: {
      "1": "UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B",
      "2": "HC wB IC JC",
      "260": "SB TB",
      "388": "8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB",
      "900": "0 1 2 3 4 5 6 7 I z J E F G A B C K L H M N O j k"
    },
    D: {
      "1": "JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "16": "I z J E F G A B C K L",
      "388": "4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB",
      "900": "0 1 2 3 H M N O j k"
    },
    E: {
      "1": "A B C K L H 3B tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "16": "I z LC 2B",
      "388": "F G OC PC",
      "900": "J E MC NC"
    },
    F: {
      "1": "6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i",
      "16": "G B UC VC WC XC tB EC",
      "388": "0 1 2 3 4 5 H M N O j k",
      "900": "C YC uB"
    },
    G: {
      "1": "gC hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "16": "2B ZC FC",
      "388": "F cC dC eC fC",
      "900": "aC bC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "1": "D",
      "16": "wB uC vC wC",
      "388": "yC zC",
      "900": "I xC FC"
    },
    J: {
      "16": "E",
      "388": "A"
    },
    K: {
      "1": "l",
      "16": "A B tB EC",
      "900": "C uB"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "900": "A B"
    },
    O: {
      "1": "0C"
    },
    P: {
      "1": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "1": "4B"
    },
    R: {
      "1": "ED"
    },
    S: {
      "1": "GD",
      "388": "FD"
    }
  },
  B: 1,
  C: "Constraint Validation API"
};
export default exports;