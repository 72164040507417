var exports = {};
exports = {
  A: {
    A: {
      "132": "J E F G A B GC"
    },
    B: {
      "2": "C K L H M N O",
      "292": "P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "1": "fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B",
      "2": "0 1 2 3 4 5 6 7 8 9 HC wB I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB IC JC",
      "3074": "eB"
    },
    D: {
      "292": "0 1 2 3 4 5 6 7 8 9 I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC"
    },
    E: {
      "16": "I z LC 2B",
      "292": "J E F G A B C K L H MC NC OC PC 3B tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC"
    },
    F: {
      "2": "G B C UC VC WC XC tB EC YC uB",
      "292": "0 1 2 3 4 5 6 7 8 9 H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i"
    },
    G: {
      "2": "qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "16": "2B ZC FC aC bC",
      "292": "cC",
      "804": "F dC eC fC gC hC iC jC kC lC mC nC oC pC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "16": "uC vC",
      "292": "wB I D wC xC FC yC zC"
    },
    J: {
      "292": "E A"
    },
    K: {
      "2": "A B C tB EC uB",
      "292": "l"
    },
    L: {
      "292": "D"
    },
    M: {
      "2": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "292": "0C"
    },
    P: {
      "292": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "292": "4B"
    },
    R: {
      "292": "ED"
    },
    S: {
      "2": "FD GD"
    }
  },
  B: 7,
  C: "CSS scrollbar styling"
};
export default exports;