var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F G A B GC"
    },
    B: {
      "1": "O P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D",
      "2": "C K L H M N"
    },
    C: {
      "1": "gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B",
      "2": "HC wB IC JC",
      "8": "0 1 2 3 4 5 6 7 8 9 I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB"
    },
    D: {
      "1": "BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "2": "I z",
      "8": "J E F",
      "132": "0 1 G A B C K L H M N O j k",
      "260": "2 3 4 5 6 7 8 9 AB"
    },
    E: {
      "1": "vB 9B AC BC CC DC SC TC",
      "2": "I z J E F G A B C K LC 2B MC NC OC PC 3B tB uB 4B",
      "516": "L H QC RC 5B 6B 7B 8B"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i",
      "2": "G UC VC WC",
      "8": "B XC",
      "132": "tB EC YC",
      "260": "C H M N O uB"
    },
    G: {
      "1": "qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "2": "F 2B ZC FC aC bC cC dC eC fC gC hC iC jC kC lC mC nC oC pC"
    },
    H: {
      "1": "tC"
    },
    I: {
      "1": "D FC yC zC",
      "2": "wB uC vC wC",
      "132": "I xC"
    },
    J: {
      "2": "E A"
    },
    K: {
      "1": "C l tB EC uB",
      "2": "A",
      "132": "B"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "0C"
    },
    P: {
      "1": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "1": "4B"
    },
    R: {
      "1": "ED"
    },
    S: {
      "1": "GD",
      "8": "FD"
    }
  },
  B: 6,
  C: "WebP image format"
};
export default exports;