var exports = {};
exports = {
  A: {
    A: {
      "1": "G A B",
      "2": "J E F GC"
    },
    B: {
      "1": "C K L H M N O P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "1": "EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B",
      "2": "0 HC wB I z J E F G A B C K L H M N O j IC JC",
      "4": "1 2 3 4 5 6 7 8 9 k AB BB CB DB"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC"
    },
    E: {
      "1": "I z J E F G A B C K L H 2B MC NC OC PC 3B tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "2": "LC"
    },
    F: {
      "1": "4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i",
      "2": "0 1 2 3 G B C H M N O j k UC VC WC XC tB EC YC uB"
    },
    G: {
      "1": "F 2B ZC FC aC bC cC dC eC fC gC hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "1": "D yC zC",
      "4": "wB I uC vC xC FC",
      "132": "wC"
    },
    J: {
      "1": "E A"
    },
    K: {
      "1": "B C l tB EC uB",
      "2": "A"
    },
    L: {
      "1": "D"
    },
    M: {
      "260": "D"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "0C"
    },
    P: {
      "1": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "1": "4B"
    },
    R: {
      "1": "ED"
    },
    S: {
      "1": "FD GD"
    }
  },
  B: 6,
  C: "MPEG-4/H.264 video format"
};
export default exports;