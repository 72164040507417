var exports = {};
exports = {
  A: {
    A: {
      "2": "GC",
      "8": "J E F G",
      "260": "A B"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D",
      "260": "C K L H",
      "1284": "M N O"
    },
    C: {
      "1": "w x y D 0B 1B",
      "8": "HC wB IC JC",
      "516": "o p q r s t u v",
      "4612": "0 1 2 3 4 5 6 7 8 9 I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n"
    },
    D: {
      "1": "kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "8": "0 I z J E F G A B C K L H M N O",
      "132": "1 2 3 4 5 6 7 8 9 j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB"
    },
    E: {
      "1": "K L H uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "8": "I z J E F G A B C LC 2B MC NC OC PC 3B tB"
    },
    F: {
      "1": "G B C fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i UC VC WC XC tB EC YC uB",
      "132": "0 1 2 3 4 5 6 7 8 9 H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB"
    },
    G: {
      "8": "F 2B ZC FC aC bC cC dC eC fC gC hC iC jC kC",
      "2049": "lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "1": "D zC",
      "8": "wB I uC vC wC xC FC yC"
    },
    J: {
      "1": "A",
      "8": "E"
    },
    K: {
      "1": "A B C l tB EC uB"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "8": "A B"
    },
    O: {
      "1": "0C"
    },
    P: {
      "1": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "1": "4B"
    },
    R: {
      "1": "ED"
    },
    S: {
      "2": "FD GD"
    }
  },
  B: 1,
  C: "Datalist element"
};
export default exports;