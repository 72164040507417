var exports = {};
exports = {
  A: {
    A: {
      "1": "G A B",
      "2": "J E F GC"
    },
    B: {
      "1": "C K L H M N O P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B",
      "2": "HC wB I z J E F G A B C K L IC JC"
    },
    D: {
      "1": "9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "132": "0 1 2 3 4 5 6 7 8 I z J E F G A B C K L H M N O j k"
    },
    E: {
      "1": "E F G A B C K L H NC OC PC 3B tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "16": "J LC 2B",
      "132": "I z MC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i uB",
      "2": "G B UC VC WC XC tB EC YC",
      "16": "C",
      "132": "H M"
    },
    G: {
      "1": "F dC eC fC gC hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "16": "2B ZC FC",
      "132": "aC bC cC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "1": "D yC zC",
      "16": "uC vC",
      "132": "wB I wC xC FC"
    },
    J: {
      "132": "E A"
    },
    K: {
      "1": "l uB",
      "2": "A B tB EC",
      "16": "C"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "0C"
    },
    P: {
      "1": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "1": "4B"
    },
    R: {
      "1": "ED"
    },
    S: {
      "1": "FD GD"
    }
  },
  B: 5,
  C: "KeyboardEvent.location"
};
export default exports;