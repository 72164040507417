var exports = {};
exports = {
  A: {
    A: {
      "1": "G A B",
      "16": "GC",
      "260": "J E F"
    },
    B: {
      "1": "C K L H M N O P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "1": "VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B",
      "132": "0 1 2 3 4 5 6 7 8 9 HC wB I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB IC JC",
      "2180": "MB NB OB PB QB RB SB TB UB"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "16": "I z J E F G A B C K L"
    },
    E: {
      "1": "J E F G A B C K L H MC NC OC PC 3B tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "16": "I z LC 2B"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i",
      "132": "G B C UC VC WC XC tB EC YC uB"
    },
    G: {
      "16": "FC",
      "132": "2B ZC",
      "516": "F aC bC cC dC eC fC gC hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "1": "D yC zC",
      "16": "wB I uC vC wC xC",
      "1025": "FC"
    },
    J: {
      "1": "A",
      "16": "E"
    },
    K: {
      "1": "l",
      "16": "A B C tB EC",
      "132": "uB"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "1": "B",
      "16": "A"
    },
    O: {
      "1": "0C"
    },
    P: {
      "1": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "1": "4B"
    },
    R: {
      "1": "ED"
    },
    S: {
      "1": "GD",
      "2180": "FD"
    }
  },
  B: 5,
  C: "Selection API"
};
export default exports;