var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F G GC",
      "132": "A B"
    },
    B: {
      "1": "C K L H M N O P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B",
      "2": "HC wB",
      "260": "A B",
      "388": "J E F G",
      "900": "I z IC JC"
    },
    D: {
      "1": "AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "16": "I z J",
      "132": "8 9",
      "388": "0 1 2 3 4 5 6 7 E F G A B C K L H M N O j k"
    },
    E: {
      "1": "F G A B C K L H OC PC 3B tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "2": "I LC 2B",
      "132": "E NC",
      "388": "z J MC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 C O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i uB",
      "2": "G B UC VC WC XC tB EC YC",
      "132": "H M N"
    },
    G: {
      "1": "F dC eC fC gC hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "2": "2B ZC FC",
      "132": "cC",
      "388": "aC bC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "1": "D zC",
      "2": "uC vC wC",
      "388": "yC",
      "900": "wB I xC FC"
    },
    J: {
      "132": "A",
      "388": "E"
    },
    K: {
      "1": "C l uB",
      "2": "A B tB EC"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "132": "A B"
    },
    O: {
      "1": "0C"
    },
    P: {
      "1": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "1": "4B"
    },
    R: {
      "1": "ED"
    },
    S: {
      "1": "FD GD"
    }
  },
  B: 1,
  C: "XMLHttpRequest advanced features"
};
export default exports;