var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F G A B GC"
    },
    B: {
      "1": "P Q R S T U V W",
      "2": "C K L H M N O",
      "1025": "X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "2": "0 1 2 3 4 5 6 7 8 9 HC wB I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l IC JC",
      "260": "oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B"
    },
    D: {
      "1": "oB pB qB rB sB P Q R S T U V W",
      "2": "0 1 2 3 4 5 6 7 8 9 I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB",
      "132": "cB yB dB eB fB gB hB iB jB kB lB mB nB l",
      "1025": "X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC"
    },
    E: {
      "2": "I z J E F G A B LC 2B MC NC OC PC 3B",
      "772": "C K L H tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC"
    },
    F: {
      "1": "dB eB fB gB hB iB jB kB lB mB nB l oB",
      "2": "0 1 2 3 4 5 6 7 8 9 G B C H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB UC VC WC XC tB EC YC uB",
      "132": "QB RB SB TB UB VB WB XB YB ZB aB bB cB",
      "1025": "pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i"
    },
    G: {
      "2": "F 2B ZC FC aC bC cC dC eC fC gC hC iC",
      "772": "jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "1": "D",
      "2": "wB I uC vC wC xC FC yC zC"
    },
    J: {
      "2": "E A"
    },
    K: {
      "2": "A B C tB EC uB",
      "1025": "l"
    },
    L: {
      "1025": "D"
    },
    M: {
      "260": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "2": "0C"
    },
    P: {
      "1": "j k 6C 7C 8C 9C AD vB BD CD DD",
      "2": "I 1C 2C 3C",
      "132": "4C 5C 3B"
    },
    Q: {
      "132": "4B"
    },
    R: {
      "1025": "ED"
    },
    S: {
      "2": "FD",
      "260": "GD"
    }
  },
  B: 7,
  C: "Feature Policy"
};
export default exports;