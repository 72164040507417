var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F G A GC",
      "132": "B"
    },
    B: {
      "1": "P Q R S",
      "132": "C K L H M N O",
      "513": "T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "1": "FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V",
      "2": "0 1 2 3 4 5 6 7 8 9 HC wB I z J E F G A B C K L H M N O j k AB BB CB DB EB IC JC",
      "513": "W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B"
    },
    D: {
      "1": "yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T",
      "2": "0 I z J E F G A B C K L H M N O j",
      "260": "1 2 3 4 5 6 7 8 9 k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB",
      "513": "U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC"
    },
    E: {
      "1": "C tB uB",
      "2": "I z J E LC 2B MC NC",
      "132": "F G A B OC PC 3B",
      "1025": "K L H 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB",
      "2": "G B C UC VC WC XC tB EC YC uB",
      "513": "l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i"
    },
    G: {
      "1": "kC lC mC nC",
      "2": "2B ZC FC aC bC cC",
      "132": "F dC eC fC gC hC iC jC",
      "1025": "oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "1": "D",
      "2": "wB I uC vC wC xC FC yC zC"
    },
    J: {
      "2": "E A"
    },
    K: {
      "2": "A B C tB EC uB",
      "513": "l"
    },
    L: {
      "513": "D"
    },
    M: {
      "513": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "0C"
    },
    P: {
      "1": "j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD",
      "2": "I"
    },
    Q: {
      "1": "4B"
    },
    R: {
      "513": "ED"
    },
    S: {
      "1": "FD GD"
    }
  },
  B: 4,
  C: "Referrer Policy"
};
export default exports;