var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F G A B GC"
    },
    B: {
      "1": "T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D",
      "2": "C K L H M N O",
      "260": "P Q R S"
    },
    C: {
      "1": "R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B",
      "2": "0 1 2 3 4 5 6 7 8 9 HC wB I z J E F G A B C K L H M N O j k AB BB IC JC",
      "260": "xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB",
      "516": "QB RB SB TB UB VB WB XB YB ZB aB bB",
      "580": "CB DB EB FB GB HB IB JB KB LB MB NB OB PB",
      "2049": "pB qB rB sB P Q"
    },
    D: {
      "1": "T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "2": "0 1 2 3 4 5 6 7 8 9 I z J E F G A B C K L H M N O j k AB BB CB DB EB",
      "132": "FB GB HB",
      "260": "IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S"
    },
    E: {
      "1": "H RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "2": "I z J E F G A LC 2B MC NC OC PC 3B",
      "1090": "B C K tB uB",
      "2049": "L 4B QC"
    },
    F: {
      "1": "mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i",
      "2": "0 1 G B C H M N O j k UC VC WC XC tB EC YC uB",
      "132": "2 3 4",
      "260": "5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB"
    },
    G: {
      "2": "F 2B ZC FC aC bC cC dC eC fC gC hC",
      "1090": "iC jC kC lC mC nC oC",
      "2049": "pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "1": "D",
      "2": "wB I uC vC wC xC FC yC zC"
    },
    J: {
      "2": "E A"
    },
    K: {
      "1": "l",
      "2": "A B C tB EC uB"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "260": "0C"
    },
    P: {
      "260": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "260": "4B"
    },
    R: {
      "1": "ED"
    },
    S: {
      "1": "GD",
      "516": "FD"
    }
  },
  B: 5,
  C: "Web Animations API"
};
export default exports;