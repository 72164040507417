var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F G GC",
      "132": "A B"
    },
    B: {
      "132": "C K L H M N O",
      "1028": "P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "2": "0 1 2 3 4 5 6 7 8 9 HC wB I z J E F G A B C K L H M N O j k AB BB IC JC",
      "1028": "kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B",
      "2564": "CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB",
      "3076": "SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB"
    },
    D: {
      "16": "I z J E",
      "132": "0 1 2 3 4 5 6 7 8 9 G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB",
      "388": "F",
      "1028": "yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC"
    },
    E: {
      "16": "I LC 2B",
      "132": "z J E F G A MC NC OC PC 3B",
      "1028": "B C K L H tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC"
    },
    F: {
      "2": "G B C UC VC WC XC tB EC YC uB",
      "132": "0 1 2 3 4 5 6 7 8 9 H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB",
      "1028": "RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i"
    },
    G: {
      "16": "2B ZC FC",
      "132": "F aC bC cC dC eC fC gC hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "132": "I xC FC yC zC",
      "292": "wB uC vC wC",
      "1028": "D"
    },
    J: {
      "16": "E",
      "132": "A"
    },
    K: {
      "2": "A B C tB EC uB",
      "1028": "l"
    },
    L: {
      "1028": "D"
    },
    M: {
      "1028": "D"
    },
    N: {
      "132": "A B"
    },
    O: {
      "1028": "0C"
    },
    P: {
      "132": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "1028": "4B"
    },
    R: {
      "1028": "ED"
    },
    S: {
      "1028": "GD",
      "2564": "FD"
    }
  },
  B: 4,
  C: "DOMMatrix"
};
export default exports;