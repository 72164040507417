var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F G A GC",
      "388": "B"
    },
    B: {
      "257": "P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D",
      "260": "C K L",
      "769": "H M N O"
    },
    C: {
      "2": "HC wB I z IC JC",
      "4": "0 1 2 3 4 5 6 7 8 9 J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB",
      "257": "XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B"
    },
    D: {
      "2": "0 I z J E F G A B C K L H M N O j",
      "4": "1 2 3 4 5 6 7 8 9 k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB",
      "257": "UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC"
    },
    E: {
      "1": "A B C K L H 3B tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "2": "I z J E LC 2B MC NC",
      "4": "F G OC PC"
    },
    F: {
      "2": "G B C UC VC WC XC tB EC YC uB",
      "4": "0 1 2 3 4 5 6 7 8 9 H M N O j k AB BB CB DB EB FB GB",
      "257": "HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i"
    },
    G: {
      "1": "gC hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "2": "2B ZC FC aC bC",
      "4": "F cC dC eC fC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "2": "wB I uC vC wC xC FC",
      "4": "yC zC",
      "257": "D"
    },
    J: {
      "2": "E",
      "4": "A"
    },
    K: {
      "2": "A B C tB EC uB",
      "257": "l"
    },
    L: {
      "257": "D"
    },
    M: {
      "257": "D"
    },
    N: {
      "2": "A",
      "388": "B"
    },
    O: {
      "257": "0C"
    },
    P: {
      "4": "I",
      "257": "j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "257": "4B"
    },
    R: {
      "257": "ED"
    },
    S: {
      "4": "FD",
      "257": "GD"
    }
  },
  B: 6,
  C: "ECMAScript 2015 (ES6)"
};
export default exports;