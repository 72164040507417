var exports = {};
exports = {
  A: {
    A: {
      "2436": "J E F G A B GC"
    },
    B: {
      "260": "N O",
      "2436": "C K L H M",
      "8196": "P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "2": "0 HC wB I z J E F G A B C K L H M N O j k IC JC",
      "772": "1 2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB",
      "4100": "KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B"
    },
    D: {
      "2": "I z J E F G A B C",
      "2564": "0 1 2 3 4 5 6 7 8 9 K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB",
      "8196": "bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "10244": "MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB"
    },
    E: {
      "1": "C K L H uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "16": "LC 2B",
      "2308": "A B 3B tB",
      "2820": "I z J E F G MC NC OC PC"
    },
    F: {
      "2": "G B UC VC WC XC tB EC YC",
      "16": "C",
      "516": "uB",
      "2564": "0 1 2 3 4 5 6 7 8 H M N O j k",
      "8196": "OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i",
      "10244": "9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB"
    },
    G: {
      "1": "kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "2": "2B ZC FC",
      "2820": "F aC bC cC dC eC fC gC hC iC jC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "2": "wB I uC vC wC xC FC",
      "260": "D",
      "2308": "yC zC"
    },
    J: {
      "2": "E",
      "2308": "A"
    },
    K: {
      "2": "A B C tB EC",
      "16": "uB",
      "8196": "l"
    },
    L: {
      "8196": "D"
    },
    M: {
      "1028": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "8196": "0C"
    },
    P: {
      "2052": "1C 2C",
      "2308": "I",
      "8196": "j k 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "8196": "4B"
    },
    R: {
      "8196": "ED"
    },
    S: {
      "4100": "FD GD"
    }
  },
  B: 5,
  C: "Synchronous Clipboard API"
};
export default exports;