var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F G A B GC"
    },
    B: {
      "2": "C K L H M N O s t u v w x y D",
      "513": "P Q R S T U V W X Y Z a b c d e f g h i m n o p q r"
    },
    C: {
      "1": "iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x",
      "2": "0 1 2 3 4 5 6 7 8 9 HC wB I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB 0B 1B IC JC",
      "322": "QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB y D"
    },
    D: {
      "2": "0 1 2 3 4 5 6 7 8 9 I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB s t u v w x y D 0B 1B KC",
      "130": "HB IB JB",
      "513": "KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g",
      "578": "h i m n o p q r"
    },
    E: {
      "1": "K L H 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "2": "I z J E F G A B C LC 2B MC NC OC PC 3B tB uB"
    },
    F: {
      "2": "0 1 2 3 4 5 6 7 8 9 G B C H M N O j k AB BB CB DB EB FB GB HB IB KB UC VC WC XC tB EC YC uB",
      "513": "JB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i"
    },
    G: {
      "1": "oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "2": "F 2B ZC FC aC bC cC dC eC fC gC hC iC jC kC lC mC nC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "2": "wB I D uC vC wC xC FC yC zC"
    },
    J: {
      "2": "E A"
    },
    K: {
      "2": "A B C l tB EC uB"
    },
    L: {
      "2": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "2": "0C"
    },
    P: {
      "2": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "2": "4B"
    },
    R: {
      "2": "ED"
    },
    S: {
      "1": "GD",
      "322": "FD"
    }
  },
  B: 7,
  C: "FIDO U2F API"
};
export default exports;