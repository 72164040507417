var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F G A GC",
      "130": "B"
    },
    B: {
      "1": "Y Z a b c d e f g h i m n o p q r s t u v w x y D",
      "16": "C K",
      "260": "L H",
      "1028": "P Q R S T U V W X",
      "5124": "M N O"
    },
    C: {
      "1": "o p q r s t u v w x y D 0B 1B",
      "2": "0 1 2 3 4 5 6 7 8 9 HC wB I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB IC JC",
      "5124": "m n",
      "7172": "gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i",
      "7746": "aB bB xB cB yB dB eB fB"
    },
    D: {
      "1": "Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "2": "0 1 2 3 4 5 6 7 8 9 I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB",
      "260": "VB WB XB YB ZB aB bB",
      "1028": "xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X"
    },
    E: {
      "2": "I z J E F G LC 2B MC NC OC PC",
      "1028": "H QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "3076": "A B C K L 3B tB uB 4B"
    },
    F: {
      "1": "qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i",
      "2": "0 1 2 3 4 5 6 7 8 9 G B C H M N O j k AB BB CB DB EB FB GB HB UC VC WC XC tB EC YC uB",
      "260": "IB JB KB LB MB NB OB",
      "1028": "PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB"
    },
    G: {
      "2": "F 2B ZC FC aC bC cC dC eC fC",
      "16": "gC",
      "1028": "hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "1": "D",
      "2": "wB I uC vC wC xC FC yC zC"
    },
    J: {
      "2": "E A"
    },
    K: {
      "1": "l",
      "2": "A B C tB EC uB"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1028": "0C"
    },
    P: {
      "1": "j k AD vB BD CD DD",
      "2": "I 1C 2C",
      "1028": "3C 4C 5C 3B 6C 7C 8C 9C"
    },
    Q: {
      "1028": "4B"
    },
    R: {
      "1": "ED"
    },
    S: {
      "2": "FD GD"
    }
  },
  B: 1,
  C: "Streams"
};
export default exports;