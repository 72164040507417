import _agents from "./agents";
import _feature from "./feature";
import _features from "./features";
import _region from "./region";
var exports = {};
exports.agents = _agents.agents;
exports.feature = _feature;
exports.features = _features.features;
exports.region = _region;
export default exports;
export const agents = exports.agents,
  feature = exports.feature,
  features = exports.features,
  region = exports.region;