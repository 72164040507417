var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F GC",
      "520": "G A B"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D",
      "8": "C K",
      "388": "L H M N O"
    },
    C: {
      "1": "7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B",
      "2": "HC wB IC JC",
      "132": "0 1 2 3 4 5 6 I z J E F G A B C K L H M N O j k"
    },
    D: {
      "1": "4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "2": "I z",
      "132": "0 1 2 3 J E F G A B C K L H M N O j k"
    },
    E: {
      "1": "vB 9B AC BC CC DC SC TC",
      "2": "LC",
      "8": "I z 2B MC",
      "520": "J E F G A B C NC OC PC 3B tB",
      "1028": "K uB 4B",
      "7172": "L",
      "8196": "H QC RC 5B 6B 7B 8B"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i",
      "2": "G UC VC WC",
      "132": "B C H XC tB EC YC uB"
    },
    G: {
      "2": "F 2B ZC FC aC bC cC dC eC fC gC hC iC jC kC",
      "1028": "lC mC nC oC pC",
      "3076": "qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "1": "D",
      "2": "uC vC",
      "132": "wB I wC xC FC yC zC"
    },
    J: {
      "2": "E A"
    },
    K: {
      "1": "l",
      "2": "A B C tB EC uB"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "8": "A B"
    },
    O: {
      "1": "0C"
    },
    P: {
      "1": "j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD",
      "132": "I"
    },
    Q: {
      "1": "4B"
    },
    R: {
      "1": "ED"
    },
    S: {
      "1": "FD GD"
    }
  },
  B: 6,
  C: "WebM video format"
};
export default exports;