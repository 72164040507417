var exports = {};
exports = {
  A: {
    A: {
      "8": "J E F G GC",
      "1924": "A B"
    },
    B: {
      "1": "C K L H M N O P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "1": "5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B",
      "8": "HC wB IC",
      "516": "3 4",
      "772": "0 1 2 I z J E F G A B C K L H M N O j k JC"
    },
    D: {
      "1": "7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "8": "I z J E",
      "516": "3 4 5 6",
      "772": "2",
      "900": "0 1 F G A B C K L H M N O j k"
    },
    E: {
      "1": "E F G A B C K L H OC PC 3B tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "8": "I z LC 2B",
      "900": "J MC NC"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i",
      "8": "G B UC VC WC XC tB",
      "900": "C EC YC uB"
    },
    G: {
      "1": "F cC dC eC fC gC hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "8": "2B ZC FC",
      "900": "aC bC"
    },
    H: {
      "900": "tC"
    },
    I: {
      "1": "D yC zC",
      "8": "uC vC wC",
      "900": "wB I xC FC"
    },
    J: {
      "1": "A",
      "900": "E"
    },
    K: {
      "1": "l",
      "8": "A B",
      "900": "C tB EC uB"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "900": "A B"
    },
    O: {
      "1": "0C"
    },
    P: {
      "1": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "1": "4B"
    },
    R: {
      "1": "ED"
    },
    S: {
      "1": "FD GD"
    }
  },
  B: 1,
  C: "classList (DOMTokenList)"
};
export default exports;