var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F G A B GC"
    },
    B: {
      "1": "e f g h i m n o p q r s t u v w x y D",
      "2": "C K L H M N O P Q",
      "516": "R S T U V W X Y Z a b c d"
    },
    C: {
      "2": "0 1 2 3 4 5 6 7 8 9 HC wB I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B IC JC"
    },
    D: {
      "2": "4 5 6 7 8 9 I z J E F G A B C K L H M N AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X",
      "130": "0 1 2 3 O j k",
      "1028": "Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC"
    },
    E: {
      "1": "L H QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "2": "I z J E F G A B C LC 2B MC NC OC PC 3B tB",
      "2049": "K uB 4B"
    },
    F: {
      "2": "0 1 2 3 4 5 6 7 8 9 G B C H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i UC VC WC XC tB EC YC uB"
    },
    G: {
      "1": "qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "2": "F 2B ZC FC aC bC cC dC eC fC gC hC iC jC kC",
      "2049": "lC mC nC oC pC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "2": "wB I uC vC wC xC FC yC",
      "258": "D zC"
    },
    J: {
      "2": "E A"
    },
    K: {
      "1": "l",
      "2": "A B C tB EC uB"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "2": "0C"
    },
    P: {
      "1": "j k 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD",
      "2": "I",
      "258": "1C 2C 3C"
    },
    Q: {
      "2": "4B"
    },
    R: {
      "2": "ED"
    },
    S: {
      "2": "FD GD"
    }
  },
  B: 4,
  C: "Web Share API"
};
export default exports;