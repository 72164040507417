var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F GC",
      "132": "A B",
      "388": "G"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D",
      "132": "C K L H M N O"
    },
    C: {
      "1": "UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B",
      "16": "HC wB IC JC",
      "132": "0 1 2 3 4 5 6 7 8 9 J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB",
      "388": "I z"
    },
    D: {
      "1": "IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC",
      "16": "I z J E F G A B C K L",
      "132": "0 1 2 3 4 5 6 7 8 9 H M N O j k AB BB CB DB EB FB GB HB"
    },
    E: {
      "1": "B C K L H 3B tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "16": "I z J LC 2B",
      "132": "E F G A NC OC PC",
      "388": "MC"
    },
    F: {
      "1": "5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i",
      "16": "G B UC VC WC XC tB EC",
      "132": "0 1 2 3 4 H M N O j k",
      "516": "C YC uB"
    },
    G: {
      "1": "hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "16": "2B ZC FC aC bC",
      "132": "F cC dC eC fC gC"
    },
    H: {
      "516": "tC"
    },
    I: {
      "1": "D",
      "16": "wB uC vC wC zC",
      "132": "yC",
      "388": "I xC FC"
    },
    J: {
      "16": "E",
      "132": "A"
    },
    K: {
      "1": "l",
      "16": "A B C tB EC",
      "516": "uB"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "132": "A B"
    },
    O: {
      "1": "0C"
    },
    P: {
      "1": "I j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "1": "4B"
    },
    R: {
      "1": "ED"
    },
    S: {
      "1": "GD",
      "132": "FD"
    }
  },
  B: 5,
  C: ":indeterminate CSS pseudo-class"
};
export default exports;