var exports = {};
exports = {
  A: {
    A: {
      "2": "J E F G A GC",
      "132": "B"
    },
    B: {
      "1": "C K L H M N O",
      "513": "P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D"
    },
    C: {
      "1": "FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB",
      "2": "0 1 2 3 4 5 6 7 8 9 HC wB I z J E F G A B C K L H M N O j k AB BB CB DB EB IC JC",
      "513": "WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B"
    },
    D: {
      "1": "KB LB MB NB OB PB QB RB SB TB",
      "2": "0 1 2 3 4 5 6 7 8 9 I z J E F G A B C K L H M N O j k AB BB CB DB EB FB GB HB IB JB",
      "513": "UB VB WB XB YB ZB aB bB xB cB yB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R S T U V W X Y Z a b c d e f g h i m n o p q r s t u v w x y D 0B 1B KC"
    },
    E: {
      "1": "B C K L H tB uB 4B QC RC 5B 6B 7B 8B vB 9B AC BC CC DC SC TC",
      "2": "I z J E F LC 2B MC NC OC",
      "260": "G A PC 3B"
    },
    F: {
      "1": "7 8 9 AB BB CB DB EB FB GB",
      "2": "0 1 2 3 4 5 6 G B C H M N O j k UC VC WC XC tB EC YC uB",
      "513": "HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB l oB pB qB rB sB P Q R zB S T U V W X Y Z a b c d e f g h i"
    },
    G: {
      "1": "eC fC gC hC iC jC kC lC mC nC oC pC qC rC sC 5B 6B 7B 8B vB 9B AC BC CC DC",
      "2": "F 2B ZC FC aC bC cC dC"
    },
    H: {
      "2": "tC"
    },
    I: {
      "2": "wB I uC vC wC xC FC yC zC",
      "513": "D"
    },
    J: {
      "2": "E A"
    },
    K: {
      "2": "A B C tB EC uB",
      "513": "l"
    },
    L: {
      "513": "D"
    },
    M: {
      "513": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "513": "0C"
    },
    P: {
      "1": "I",
      "513": "j k 1C 2C 3C 4C 5C 3B 6C 7C 8C 9C AD vB BD CD DD"
    },
    Q: {
      "513": "4B"
    },
    R: {
      "513": "ED"
    },
    S: {
      "1": "FD",
      "513": "GD"
    }
  },
  B: 6,
  C: "HTTP/2 protocol"
};
export default exports;